import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, mergeMap, switchMap, filter} from "rxjs/operators";
import {of} from "rxjs";
import {PayloadAction} from "redux-starter-kit";
import {addAlert} from "common-web";
import {changeFormInitialized, changeShowFormLoader, changeInquiryForm, changeFormSteps,
    changeIsClinicHost} from "../reducers/axaInsuranceSlice";
import {getTreatmentsAPI} from "../../api/getTreatments";
import moment from "moment";
import {changeIsAuthenticated, setSecret, IChangeIsAuthenticated} from 'store/reducers/authSlice';
import {changeTreatmentCategories, ITreatmentCategory} from 'store/reducers/metadataSlice';
import {RootState} from '../reducers';
import {stepsAuthenticated, stepsNotAuthenticated} from '../../components/AxaHost/formSteps';
import {stepsClinic, stepsInsuranceForPatient} from '../../components/ClinicAxaHost/formSteps';
import {isClinicStepsFormExpandedSelector} from "../selectors/axaInsuranceSelectors";
import {isUUID} from "../../utils/inquiryUtils";

const retrieveFormInitialDataEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(changeIsClinicHost.type),
        switchMap((): any => {
            return getTreatmentsAPI().pipe(
                mergeMap((resp: any) => {
                    const urlParams = new URLSearchParams(state$.value.router.location.search),
                        cid = (urlParams).get("cid"),
                        startDate = (urlParams).get("startDate"),
                        endDate = (urlParams).get("endDate"),
                        secret = (urlParams).get("s"),
                        treatmentCategories: ITreatmentCategory[] = [];
                    resp['hydra:member'].map((dataItem: any) => treatmentCategories.push({
                        value: dataItem.id,
                        labels: dataItem.nameTranslations
                    }));

                    if(secret !== undefined) {
                        setSecret(secret);
                    }

                    const inquiryFormFields: any = {};

                    if (cid && isUUID(cid)) {
                        inquiryFormFields['cid'] = cid;
                    }

                    if (startDate) {
                        inquiryFormFields['departure'] = moment(startDate).format('YYYY-MM-DD');
                    }

                    if (endDate) {
                        inquiryFormFields['return'] = moment(endDate).format('YYYY-MM-DD');
                    }

                    let steps = state$.value.auth.isAuthenticated ? stepsAuthenticated : stepsNotAuthenticated;
                    if (state$.value.axaInsurance.isClinicHost) {
                        const isClinicStepsFormExpanded = isClinicStepsFormExpandedSelector(state$.value);
                        steps = isClinicStepsFormExpanded ? stepsInsuranceForPatient : stepsClinic;
                    }

                    return [
                        changeTreatmentCategories(treatmentCategories),
                        changeFormInitialized(),
                        changeShowFormLoader(false),
                        changeInquiryForm(inquiryFormFields),
                        changeFormSteps(Array.from(steps))
                    ];
                }),
                catchError((error: any) => {
                    return of(
                            changeShowFormLoader(false),
                            addAlert({message: error.response ? error.response['hydra:description'] : 'Error occurred'})
                        )
                    }
                ))
        })
    );

const inquiryWizardEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeIsAuthenticated.type),
        filter(() => !state$.value.axaInsurance.isClinicHost),
        switchMap((action: PayloadAction<IChangeIsAuthenticated>): any => {
            const steps = action.payload.isAuthenticated ? stepsAuthenticated : stepsNotAuthenticated;
                return of(changeFormSteps(steps));
        }),
        catchError(() => of(addAlert('Inquiry Wizard auth failed.')))
    );

const initFormEpic = combineEpics(
    retrieveFormInitialDataEpic,
    inquiryWizardEpic,
);

export default initFormEpic;
